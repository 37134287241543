<template>
  <p :class="{ error: isError }">
    <slot></slot>
  </p>
</template>

<script>
export default {
  props: ["isError"],
};
</script>

<style lang="scss" scoped>
p {
  color: green;
  padding-top: 2rem;
  text-align: center;
  font-size: 15px;
  margin: 1rem 0;
}

.error {
  color: red;
}
</style>
